import { Inject, Injectable, forwardRef } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import lodash from 'lodash';
import { CustomTokenReqType, IndCertStatus, OrgType, PhotoStatus, Role, TimeVaryingMethod, UseState } from '../../constants/enums';
import { HttpClient } from '@angular/common/http';
import { CommonServiceService } from '../common-service.service';
import { CacheKeys, CacheService } from '../cache/cache.service';
import { ApiHelperService, CloudFnNames } from '../apiHelperService/api-helper.service';
import { BehaviorSubject, Observable, Subscription, debounceTime, first, from, map, of, take } from 'rxjs';
import { environment } from '../../../../environments/environment.stage';
import { UserService } from '../user/user.service';
import moment from 'moment';
import { SettingsService } from '../settings/settings.service';
import { OrganizationService } from '../organization/organization.service';
import { AuthService } from '../auth/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ApiService } from '../api/api.service';
import { AuthDependency, IndSearchType } from '../../interface/types';

const collection = "individuals";
@Injectable({
  providedIn: 'root',
})
export class IndividualService {
  public list: AngularFireList<{}>;
  public listsReady: boolean;
  public individualsList: Individual[] = [];

  /**@deprecated Don't use this list by index as its not removing ind when ind gets removed from db */
  public indsByIndex: Individual[];

  public indsByHash: string[];
  public indsByLicense: string[];
  public indsByBarcode: string[];
  public indsByRole: any;
  public callbacks: any[] = [];
  public updateStaticListsSubscription: any;
  public childAddedSubscription: Subscription;
  public childRemovedSubscription: Subscription;
  public childUpdatedSubscription: Subscription;
  public individualRemoveListener: Subscription;
  public preparing: boolean = false;

  public indsListSubject = new BehaviorSubject<Individual[]>([]);

  readonly checkingInStatusSynonyms = ["checking in"];
  readonly checkedInStatusSynonyms = ["checked in"];

  photoUsedInIDPrefValue: string = "";
  constructor(
    // public events: Events,
    @Inject(forwardRef(() => ApiService)) public api,
    @Inject(forwardRef(() => UserService)) public user,
    // @Inject(forwardRef(() => Visits)) public visits,
    // @Inject(forwardRef(() => Csv)) public csv,
    // @Inject(forwardRef(() => AdmissibeUtil)) public admissibeUtil,
    // @Inject(forwardRef(() => LeagueDivisionsProvider))
    // public leagueDivisionsProvider,
    @Inject(forwardRef(() => CommonServiceService))
    public commonFun: CommonServiceService,
    public db: AngularFireDatabase,
    public http: HttpClient,
    @Inject(forwardRef(() => IndividualApiService)) public individualApi,
    @Inject(forwardRef(() => SettingsService)) public settingsProvider,
    // public getIndPhotoUsingPrefSettings: GetIndPhotoUsingPrefSettings,
    @Inject(forwardRef(() => OrganizationService)) public orgProvider,
    @Inject(forwardRef(() => ApiHelperService)) public apiHelperProvider,
    @Inject(forwardRef(() => AuthService)) public authProvider,
    public afAuth: AngularFireAuth,
  ) {
    this.commonFun.addH5Trace({
      f: "individuals constructor",
      t: moment().valueOf(),
    });

    this.listsReady = false;
    // let interval = window.setInterval(() => {
    //   if (this.user && this.user.orgID) {
    //     window.clearInterval(interval);
    //     this.list = this.db.list("/individuals/" + this.user.orgID + "/");
    //     this.updateStaticLists();
    //   } else if (
    //     this.user &&
    //     (this.user.role == Role.STUDIO || this.user.role == Role.SUPERADMIN)
    //   ) {
    //     window.clearInterval(interval);
    //   }
    // }, 100);

    if (this.user && this.user.orgID) {
      this.commonFun.getReportingYear(this.user.orgID).then((res) => {
        this.currentCycle = res;
      });
    }

    this.orgProvider.getSpecificOrgPrefValue("photoUsedInID").then((res) => {
      this.photoUsedInIDPrefValue = res;
    });
  }

  exportImagesAsZip(arr) {
    return this.http.post("https://high5.id/download2/clientpost", arr);
  }

  static isHashValid(hash: string) {
    let valid = !lodash.isEmpty(hash);

    if (valid === true && (hash.match(/_/g) || []).length < 2) {
      valid = false;
    }

    return valid;
  }

  isIndRoleStaff(role: string) {
    if (role === "Staff" || role === "Teacher") {
      return true;
    }

    return false;
  }

  isIndRoleStudent(role: string) {
    if (role === "Student") {
      return true;
    }

    return false;
  }
  monitorIndividualsSub(orgID: string, sendOrgIDInCallback?) {
    return from((this.db.list(`/individuals/${orgID}`)).snapshotChanges())
      .pipe(debounceTime(50), map((individuals) => {
        let indList = [];
        lodash.each(individuals, (entry) => {
          let value: any = entry.payload.val();
          if (!lodash.isObject(value)) return;
          value["key"] = entry.payload.key;
          let exists: any = new Individual();
          exists._new = false;
          exists.copyInto(value, entry.payload.key);
          if (
            exists.disabled != true &&
            exists.firstName &&
            exists.lastName &&
            this.commonFun.isDateTimeRangValid(
              exists.idValid_mode,
              'now',
              exists.idValid_startDateTime,
              exists.idValid_endDateTime,
              exists.idValid_invalidAfterDateTime,
              exists.idValid_validAfterDateTime
            )
          ) {
            indList.push(exists);
          }
        });
        return sendOrgIDInCallback ? { indList, orgID } : indList;
      }));
  }


  // --- read inds matching given value of given prop
  async readIndsMatchingPropValue(orgID: string, propKey, propValue) {
    if (!orgID || !propKey || !propValue) throw new Error("params missing!");

    return (
      await this.db
        .list(`individuals/${orgID}`)
        .query.orderByChild(propKey)
        .equalTo(propValue)
        .once("value")
    ).val();
  }

  createChunk(filter) {
    let chunkArr = lodash.chunk(filter, filter.length);
    return chunkArr;
  }

  guid(len) {
    var buf = [],
      chars = "0123456789",
      charlen = chars.length,
      length = len || 32;
    for (var i = 0; i < length; i++) {
      buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
    }
    return buf.join("");
  }

  currentCycle;
  dateFormat = (date, field?) => {
    if (field) {
      return moment(date).format("YYYYMMDD");
    } else {
      return moment(parseInt(date)).format("YYYY/MM/DD");
    }
  };

  getInd(id, orgID) {
    if (id != "" && orgID != "") {
      return this.db
        .object(`/individuals/${orgID}/${id}`)
        .valueChanges();
    }else return null;
  }

  // --- get individual name
  async getIndName(orgID, indID) {
    let readNamePromises = [];
    readNamePromises.push(this.getIndProp(orgID, indID, "firstName"));
    readNamePromises.push(this.getIndProp(orgID, indID, "lastName"));
    let [names, err] = await this.commonFun.executePromise(
      Promise.all(readNamePromises)
    );
    if (err) return "Unknown";
    if (!lodash.nth(names, 0) && !lodash.nth(names, 1)) {
      names[0] = "Deleted";
      names[1] = "Ind";
    }
    return lodash.join(names, " ");
  }

  async getIndProp(orgID, indID, prop) {
    let snapshot = await this.db
      .object(`individuals/${orgID}/${indID}/${prop}`)
      .query.once("value");
    return snapshot.val();
  }

  getIndividualFromDB(path: string, callback: Function) {
    return new Promise((resolve) => {
      let subscription = this.db
        .object(path)
        .snapshotChanges()
        .subscribe((entry) => {
          if (subscription) {
            subscription.unsubscribe();
          }

          const value = entry.payload.val();
          if (value) {
            const exists: Individual = new Individual();
            exists._new = false;
            exists.copyInto(value, entry.key);
            if (callback) callback(exists);
            return resolve(exists);
          } else {
            return resolve('')
          }
        });
    });
  }

  async addIndividual(
    individual: Individual,
    callback: Function,
    bypassOffenders?: boolean,
    customIndID?: string,
    customOrgID?: string,
    propsObj?: any
  ) {
    // remove utility properties
    individual.clearInternal();

    individual.timestampCreated = moment().valueOf();
    individual.timestampUpdated = individual.timestampCreated;

    let bypass = false;
    if (bypassOffenders) {
      bypass = bypassOffenders;
    }
    if (individual.firstName) {
      individual.firstName = individual.firstName.replace(
        /[\u{0080}-\u{FFFF}]/gu,
        ""
      );
    }
    if (individual.lastName) {
      individual.lastName = individual.lastName.replace(
        /[\u{0080}-\u{FFFF}]/gu,
        ""
      );
    }
    // --- TODO FIX missing role
    if (!individual.role) individual.role = "Visitor";

    let self = this;
    if (!lodash.isEmpty(propsObj)) {
      individual = {
        ...individual,
        ...propsObj,
      };
    }
    async function _add() {
      let indKey = customIndID || self.commonFun.createIndId();
      individual._key = indKey;
      await self.db
        .object(`/individuals/${customOrgID || self.user.orgID}/${indKey}`)
        .set(individual);

      if (bypass !== true) {
        self.lookUpOffender(individual, undefined);
      }
      return indKey;
    }

    // check if we still have a base64, if yes then save it to storage
    if (individual.fileObj) {
      this.individualUpdatePhoto(
        individual,
        individual.fileObj,
        async (url) => {
          individual.photoUrl = url;
          const key = await _add();
          if (callback) callback(key);
        }
      ).catch(async () => {
        const key = await _add();
        if (callback) callback(key);
      });
    } else {
      const key = await _add();
      if (callback) callback(key);
    }
  }

  public form: any = {
    photoStatus: undefined,
    firstName: "Guardian of",
    lastName: "",
    role: "Guardian",
    photo: undefined,
    email: undefined,
    mobilePhone: undefined,
  // Bus Pass
    accessBus_mode: TimeVaryingMethod.NO,
    accessBus_startDateTime: undefined,
    accessBus_endDateTime: undefined,
    accessBus_validAfterDateTime: undefined,
    accessBus_invalidAfterDateTime: undefined,

    // Campus Access
    accessCampus_mode: TimeVaryingMethod.YES,
    accessCampus_startDateTime: undefined,
    accessCampus_endDateTime: undefined,
    accessCampus_validAfterDateTime: undefined,
    accessCampus_invalidAfterDateTime: undefined,

    // Cafeteria Access
    accessCafeteria_mode: TimeVaryingMethod.YES,
    accessCafeteria_startDateTime: undefined,
    accessCafeteria_endDateTime: undefined,
    accessCafeteria_validAfterDateTime: undefined,
    accessCafeteria_invalidAfterDateTime: undefined,

    // Id Valid
    idValid_mode: TimeVaryingMethod.YES,
    idValid_startDateTime: undefined,
    idValid_endDateTime: undefined,
    idValid_validAfterDateTime: undefined,
    idValid_invalidAfterDateTime: undefined,

    // Parking Access
    accessParking_mode: TimeVaryingMethod.NO,
    accessParking_startDateTime: undefined,
    accessParking_endDateTime: undefined,
    accessParking_validAfterDateTime: undefined,
    accessParking_invalidAfterDateTime: undefined,

    // Social Access
    accessSocial_mode: TimeVaryingMethod.YES,
    accessSocial_startDateTime: undefined,
    accessSocial_endDateTime: undefined,
    accessSocial_validAfterDateTime: undefined,
    accessSocial_invalidAfterDateTime: undefined,

    // Athletics Access
    accessAthletics_mode: TimeVaryingMethod.YES,
    accessAthletics_startDateTime: undefined,
    accessAthletics_endDateTime: undefined,
    accessAthletics_validAfterDateTime: undefined,
    accessAthletics_invalidAfterDateTime: undefined,

    // Permission Flex Schedule
    permissionFlexSchedule_mode: TimeVaryingMethod.NO,
    permissionFlexSchedule_startDateTime: undefined,
    permissionFlexSchedule_endDateTime: undefined,
    permissionFlexSchedule_validAfterDateTime: undefined,
    permissionFlexSchedule_invalidAfterDateTime: undefined,

    // Permission ToLeave
    permissionToLeave_mode: TimeVaryingMethod.NO,
    permissionToLeave_startDateTime: undefined,
    permissionToLeave_endDateTime: undefined,
    permissionToLeave_validAfterDateTime: undefined,
    permissionToLeave_invalidAfterDateTime: undefined,

    ASBMember: undefined,
    pointBalance: undefined,
    // type school
    class: undefined,
    teacher: undefined,
    license: undefined,
    studentID: undefined,
    birth: undefined,
    RFID: undefined,
    // ---
    // type conference
    phone: undefined,
    company: undefined,
    groupID: undefined,
    addressPart1: undefined,
    addressPart2: undefined,
    postalCode: undefined,
    city: undefined,
    state: undefined,
    country: undefined,
    specialEvents: undefined,

    // type sports team
    team: undefined,
    coach: undefined,
    jerseyNumber: undefined,
    position: undefined,
    weight: undefined,
    height1: undefined,
    height2: undefined,
    division: undefined,
    ageClass: undefined,
    weightClass: undefined,

    group: undefined,
    department: undefined,
    title: undefined,
    section: undefined,
    custom1: undefined,
    custom2: undefined,
    custom3: undefined,
    auxID: undefined,
    lockerNumber: undefined,
    lockerCombination: undefined,
    teammatePURL: undefined,
    guardianEmail: undefined,
    guardianPhone: undefined,
    status: undefined,
    admissibilityGroup: undefined,
    salutation: undefined,
    suffix: undefined,
    middleName: undefined,
    nickName: undefined,
    graduationYear: undefined,
    homeRoom: undefined,
    period: undefined,
    track: undefined,
    schoolDepartment: undefined,
    campus: undefined,
    lunchPeriod: undefined,
    yearbookOrdered: undefined,
    yearbookPaid: undefined,
    yearbookCollected: undefined,
    optin: null,
    relations: undefined,
    hairColor: undefined,
    eyeColor: undefined,
    indID_Client: undefined,
    program: undefined,
    programSection: undefined,
    playStatus: undefined,
    paymentStatus: undefined,
    bookCode: undefined,
    isGuardian: undefined,
    // ---
  };

  // --- create guardian (if required) for given individual
  async getCreateGuardianPromises(
    ind,
    orgID,
    indsList?,
    relations?,
    defIDStatus?,
    isBulkOperation?
  ) {
    // individual object validation
    if (ind && (!ind._key || ind.role == "Guardian")) return null;

    // --- prepare unique list of guardians info
    let guardiansInfo = [];
    const pushInfoInList = (
      emailKey,
      phoneKey,
      fullNameKey,
      firstNameKey,
      lastNameKey
    ) => {
      if (ind[emailKey] || ind[phoneKey])
        guardiansInfo.push({
          email: ind[emailKey] || null,
          phone: ind[phoneKey] || null,
          fullName: ind[fullNameKey] || null,
          firstName: ind[firstNameKey] || null,
          lastName: ind[lastNameKey] || null,
        });
    };
    pushInfoInList(
      "guardianEmail",
      "guardianPhone",
      "guardianFullName",
      "guardianFirstName",
      "guardianLastName"
    );
    pushInfoInList(
      "guardianEmail2",
      "guardianPhone2",
      "guardianFullName2",
      "guardian2FirstName",
      "guardian2LastName"
    );
    pushInfoInList(
      "guardianEmail3",
      "guardianPhone3",
      "guardianFullName3",
      "guardian3FirstName",
      "guardian3LastName"
    );
    pushInfoInList(
      "guardianEmail4",
      "guardianPhone4",
      "guardianFullName4",
      "guardian4FirstName",
      "guardian4LastName"
    );
    guardiansInfo = lodash.uniqWith(
      guardiansInfo,
      (item1, item2) => item1.email == item2.email && item1.phone == item2.phone
    );
    //

    // --- if ind does not have any guardian email/phone, return from here.
    if (guardiansInfo.length == 0) return null;

    // --- read prerequisite data
    let readInitialDataPromises = [];
    // --- fetch inds list
    if (!indsList)
      readInitialDataPromises.push(
        this.monitorIndividualsSub(orgID).pipe(take(1)).toPromise()
      );
    else readInitialDataPromises.push(of(indsList).toPromise());
    // --- fetch relations
    if (!relations)
      readInitialDataPromises.push(
        this.commonFun.promisify(
          this.individualApi.getAllRelations,
          2,
          this.individualApi
        )(orgID, ind._key)
      );
    else readInitialDataPromises.push(of(relations).toPromise());
    if (lodash.isNil(defIDStatus))
      readInitialDataPromises.push(
        this.settingsProvider.getPrefValue(orgID, "idValid_mode", null, "value")
      );
    else readInitialDataPromises.push(of(defIDStatus).toPromise());

    // --- resolve readInitialDataPromises
    let readInitialDataPromisesRes = await Promise.all(readInitialDataPromises);
    indsList = readInitialDataPromisesRes[0];
    relations = readInitialDataPromisesRes[1];
    defIDStatus = readInitialDataPromisesRes[2];

    // --- helper function to parse email to proper format
    const parseEmail = (email) => {
      return lodash.toLower(email).trim().valueOf();
    };

    // --- helper function to parse phone to replace unnecessary chars
    const parsePhone = (phone) => {
      return lodash
        .replace(phone, /[\(\) -]*/gi, "")
        .valueOf();
    };

    let dataUpdatePromises: Promise<any>[] = [];
    let counts = {
      createdInds: 0,
      addedRelations: 0,
    };
    let createdIndIDs: string[] = [];

    lodash.each(guardiansInfo, (guardianInfo) => {
      let guardianEmail = guardianInfo.email;
      let guardianPhone = guardianInfo.phone;
      let guardianFullName = guardianInfo.fullName;
      let guardianFirstName = guardianInfo.firstName;
      let guardianLastName = guardianInfo.lastName;

      // --- if ind have guardian email or phone,
      // lookup for existing individual with the same email or phone
      if (guardianEmail) guardianEmail = parseEmail(guardianEmail);
      let matchingInds = lodash.filter(indsList, (innerInd) => {
        if (lodash.toLower(innerInd.role) != "guardian") return false;
        if (!innerInd.email && !innerInd.mobilePhone) return false;

        return (
          (innerInd.email &&
            guardianEmail &&
            parseEmail(innerInd.email) == guardianEmail) ||
          (innerInd.mobilePhone &&
            guardianPhone &&
            parsePhone(innerInd.mobilePhone) == parsePhone(guardianPhone))
        );
      });

      if (matchingInds.length > 0) {
        lodash.each(matchingInds, (matchingInd) => {
          // --- update missing info (eg. email/phone/name)

          let indUpdateObj = {};
          // --- update phone
          if (guardianPhone && matchingInd.mobilePhone != guardianPhone) {
            matchingInd.mobilePhone = guardianPhone;
            indUpdateObj["mobilePhone"] = guardianPhone;
          }

          // --- update email
          if (guardianEmail && matchingInd.email != guardianEmail) {
            matchingInd.email = guardianEmail;
            indUpdateObj["email"] = guardianEmail;
          }

          // --- update name
          if (guardianFullName || guardianFirstName || guardianLastName) {
            let firstName = guardianFirstName;
            let lastName = guardianLastName;

            if (!firstName || !lastName) {
              let nameParts = guardianFullName.split(" ");
              if (!firstName) firstName = lodash.nth(nameParts, 0);
              if (!lastName) lastName = lodash.nth(nameParts, 1);
            }

            if (firstName && matchingInd.firstName != firstName) {
              matchingInd.firstName = firstName;
              indUpdateObj["firstName"] = firstName;
            }
            if (lastName && matchingInd.lastName != lastName) {
              matchingInd.lastName = lastName;
              indUpdateObj["lastName"] = lastName;
            }
          }

          // --- add promise to update ind details in DB
          if (!lodash.isEmpty(indUpdateObj))
            dataUpdatePromises.push(
              this.updateIndividual(matchingInd._key, indUpdateObj)
            );

          // --- update relations (if not present already)
          let matchedRelationFound = lodash.filter(
            relations,
            (r) =>
              (r.relation == "parents" &&
                r.relativeIndId == matchingInd._key &&
                r.indId == ind._key) ||
              (r.relation == "dependents" &&
                r.indId == matchingInd._key &&
                r.relativeIndId == ind._key)
          );

          // --- if matching relation not found, create a new relation
          if (matchedRelationFound.length == 0) {
            counts.addedRelations++;
            let relationObj = {
              indId: ind._key,
              relativeIndId: matchingInd._key,
              relation: "parents",
            };
            relations.push(relationObj); // add relation to local list
            dataUpdatePromises.push(
              this.individualApi.addRelations(orgID, relationObj)
            ); // add relation in DB
          }
        });
      } else {
        // --- no matching ind (guardian) found with given phone/email
        // so, create a new one
        let individual: any = new Individual();
        let indID = this.commonFun.createIndId();
        individual._new = true;
        individual._key = indID;
        individual.email = guardianEmail || null;
        individual.mobilePhone = guardianPhone || null;

        individual.firstName = guardianFirstName;
        individual.lastName = guardianLastName;
        if (!individual.firstName || !individual.lastName) {
          if (!guardianFullName) guardianFullName = "";
          let nameParts = guardianFullName.split(" ");
          if (!individual.firstName)
            individual.firstName =
              nameParts.length > 2
                ? `${lodash.nth(nameParts, 0)} ${lodash.nth(nameParts, 1)}`
                : lodash.nth(nameParts, 0) || "Guardian of";
          if (!individual.lastName)
            individual.lastName =
              lodash.nth(nameParts, nameParts.length > 2 ? 2 : 1) ||
              ind.lastName;
        }

        individual.role = "Guardian";
        individual.hash = Individual.createHash(
          individual.firstName,
          individual.lastName,
          orgID
        );
        individual.timestampCreated = moment().valueOf();
        individual.idValid_mode = !lodash.isNil(defIDStatus) ? defIDStatus : null;

        if (isBulkOperation) individual.isBulkOperation = isBulkOperation;
        createdIndIDs.push(indID);

        counts.createdInds++;
        indsList.push(individual); // add individual to local list
        dataUpdatePromises.push(
          this.commonFun.promisify(this.addIndividual, 1, this)(
            individual,
            null,
            true,
            indID
          )
        ); // add individual in DB

        // --- create relation guardian with newly created individual
        counts.addedRelations++;
        let relationObj = {
          indId: ind._key,
          relativeIndId: individual._key,
          relation: "parents",
        };
        relations.push(relationObj); // add relation to local list
        dataUpdatePromises.push(
          this.individualApi.addRelations(orgID, relationObj)
        ); // add relation in DB
      }
    });

    // --- remove ind guardian props
    let indUpdateObj = {
      guardianFullName: null,
      guardianEmail: null,
      guardianPhone: null,
      guardianFullName2: null,
      guardianEmail2: null,
      guardianPhone2: null,
      guardianFullName3: null,
      guardianEmail3: null,
      guardianPhone3: null,
      guardianFullName4: null,
      guardianEmail4: null,
      guardianPhone4: null,
    };
    dataUpdatePromises.push(this.updateIndividual(ind._key, indUpdateObj));

    return { promises: dataUpdatePromises, counts, createdIndIDs };
  }

  createManualGuardian = async (orgID, individual: Individual) => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await this.getCreateGuardianPromises(individual, orgID);
        //
        if (result && result.promises) {
          await Promise.all(result.promises.map((p) => p.catch((e) => e)));
          this.db
            .object(`/individuals/${orgID}/${individual._key}`)
            .update({ guardianEmail: null, guardianPhone: null });
        }
        resolve("");
      } catch (e) { }
    });
  };

  ///////////////////////////////////////////////////////////
  /////////////////End///////////////////////////////////////
  ///////////////////////////////////////////////////////////

  /**
   * archive individual
   */
  async archiveIndividual(orgID, indData, indKey, relativeIndKey: string, isDeletedFrom:string) {
    if (!orgID) orgID = this.user.orgID;
    if (!indData)
      indData = await this.getIndividualFromDB(
        `individuals/${orgID}/${indKey}`,
        null
      );

    if (!orgID || !indData || !indKey) {
      return;
    }
    indData = lodash.cloneDeep(indData);
    indData = lodash.omitBy(indData, lodash.isNil);
    indData = lodash.omitBy(indData, (propVal) => propVal == "");
    indData = lodash.omitBy(indData, lodash.isFunction);
    indData.isDeletedFrom = isDeletedFrom ? isDeletedFrom : null;
    indData.matchedIndKey = relativeIndKey ? relativeIndKey : null;
    delete indData._key;
    delete indData.key;
    delete indData._new;
    delete indData.DBkey;
    delete indData.tempSerialNo;
    delete indData.selected;
    delete indData.accessCampus_mode;
    let promises = [];
    indData.timestamp_archived = Number(moment().format("x"));
    promises.push(
      this.db.object(`individuals-archived/${orgID}/${indKey}`).update(indData)
    );
    promises.push(this.db.object(`individuals/${orgID}/${indKey}`).remove());
    await Promise.all(promises);
  }

  // --- update individual
  updateIndividual(key: string, data: any, path = "", orgID?) {
    let promises = [];
    // --- update data promise
    promises.push(
      this.db
        .object(`/individuals/${orgID || this.user.orgID}/${key}/${path}`)
        .update(data)
    );

    // --- update timestampUpdated promise
    promises.push(
      this.db
        .object(
          `individuals/${orgID || this.user.orgID}/${key}/timestampUpdated`
        )
        .set(Number(moment().format("x")))
    );

    return Promise.all(promises);
  }
  individualUpdatePhoto(
    individual: Individual,
    newPhoto: any,
    callback?: Function
  ) {
    return new Promise(async (resolve, reject) => {
      // save photo
      // create hash and upload photo when hash is not found
      if (!individual.hash)
        individual.hash = Individual.createHash(
          individual.firstName,
          individual.lastName,
          this.user.orgID
        );

      // to FIX a SERIOUS bug of removing all org photos
      if (lodash.isEmpty(individual.hash)) {
        reject();
        if (lodash.isFunction(callback)) callback();
        return;
      }
      let signUrlReqObj = {
        extensions: [],
        path: "",
      };
      const fileType: string =
        newPhoto && newPhoto.type && newPhoto.type.split("/")[1];
      signUrlReqObj.extensions.push(fileType);
      signUrlReqObj.path = `photos/individuals/${this.user.orgID}/`;
      let fileName = await this.api.imageUpload(signUrlReqObj, newPhoto);
      let url = `${environment.awsImageUpload.awsImageEndPoint}/${signUrlReqObj.path}${fileName}`;

      individual.photoUrl = url;

      let obj: any = {
        photoUrl: url,
        photoUpdated: moment().valueOf(),
      };
      if (newPhoto && newPhoto.name)
        obj.photoFromCsv = newPhoto.name ? newPhoto.name : null;

      obj.photoUpdated = moment().valueOf();
      obj.plicPhotoID = null;
      obj.photoCropDetails = null;
      this.updateIndividual(
        individual._key,
        obj,
        individual.photoYear ? `photos/${individual.photoYear}/` : ""
      );
      if (lodash.isFunction(callback)) callback(url);
      resolve({
        url: url,
      });
    });
  }

  async removeBulkAuthUsersAndInds(importIndList: any[], filteredIndList: any[]) {
    let importListStudentIds = lodash.map(importIndList, (ind) => String(ind.studentID));
    let indListStudentIds = lodash.map(filteredIndList, (ind) => String(ind.studentID));

    let missingStudentIds: any = lodash.difference(
      indListStudentIds,
      importListStudentIds
    );

    let removedInds: any[] = lodash.filter(filteredIndList, (obj) =>
      missingStudentIds.includes(obj.studentID)
    );

    let removedUidList = lodash.map(removedInds, "uid");
    let removeIndsIdList = lodash.map(removedInds, "_key");

    let indsUpdateObj: any = {};
    let indRemoveObj: any = {};

    if (removedInds.length > 0) {
      for (let IndKey of removeIndsIdList) {
        indsUpdateObj[`${IndKey}/isBulkDelete`] = true;
        indRemoveObj[`${IndKey}`] = null;
      }

      if (!lodash.isEmpty(indsUpdateObj))
        await this.individualApi.bulkUpdateIndividuals(
          this.user.orgID,
          indsUpdateObj,
          undefined,
          true
        );

      if (!lodash.isEmpty(indRemoveObj))
        await this.individualApi.bulkUpdateIndividuals(
          this.user.orgID,
          indRemoveObj,
          undefined,
          true
        );

      // remove auth user from fb auth
      if (!lodash.isEmpty(removedUidList))
        await this.commonFun.executePromise(
          this.authProvider.bulkRemoveUsers(removedUidList)
        );
    }
  }

  lookUpOffender(individual: Individual, callback: Function) {
    let dob: any;

    if (individual.birth) {
      dob = individual.birth.split("/");
      dob = dob[1] + "/" + dob[2] + "/" + dob[0];
    }
    let fName: string = individual.firstName;
    let lName: string = individual.lastName;
    let url =
      "https://services.familywatchdog.us/rest/json.asp?key=D646DA94-E238-47FF-AEA8-8A8FFC40BBA8&type=searchbynamedob&fname=" +
      fName +
      "&lname=" +
      lName;

    if (dob) {
      url += "&dob=" + dob;
    }

    this.api.get(url).subscribe((res) => {
      if (res.offenders && res.offenders.length > 0) {
        if (!individual.lists) {
          individual.lists = {};
        }

        if (!individual.lists.offenders) {
          individual.lists.offenders = {};
          individual.lists.offenders.list = [];
        }

        lodash.each(res.offenders, (offender) => {
          if (
            lodash.find(individual.lists.offenders.list, {
              offenderid: offender.offenderid,
            }) === undefined
          ) {
            individual.lists.offenders.checked = false;

            let copy = lodash.cloneDeep(offender);
            copy._checked = false;
            individual.lists.offenders.list.push(copy);
          }
        });

        // last check timestamp
        individual.lists.offenders.timestampLastChecked = new Date().getTime();

        this.updateOffendersList(individual._key, individual.lists.offenders);

        // check if there is an unchecked offender
        if (
          lodash.findIndex(individual.lists.offenders.list, {
            _checked: false,
          }) === -1
        ) {
          if (callback) {
            callback(false);
          }
        } else {
          if (callback) {
            callback(true);
          }
        }
      } else {
        if (
          res &&
          res.error &&
          res.error.toLowerCase().includes("search balance is 0")
        ) {
          callback("licExpired");
        } else if (callback) {
          callback(false);
        }
      }
    });
  }

  updateOffendersList(key: string, list: any): Promise<void> {
    return this.db
      .object(`/individuals/${this.user.orgID}/${key}/lists/offenders/`)
      .set(list);
  }

  /**
   * bulk update individuals node of particular organization
   * @param orgID organization id
   * @param authID user firebase id
   */
  async getIndividualDataUsingUid(orgID: string, uid: string) {
    if (!orgID || !uid) throw Error("Params missing!");

    let snapshot = await this.db
      .list(`individuals/${orgID}`)
      .query.orderByChild("uid")
      .equalTo(uid)
      .once("value");
    return snapshot.val();
  }
  // --- refresh high5 fields list
  getHigh5Fields(orgType: OrgType, toBeRemoveFields: string[] = []) {
    // --- list of fields not to be offered in change monitoring fields list
    const toBeRemovedFields = [
      ...toBeRemoveFields,
      "key",
      "photoUrl",
      "photo",
      "password",
      "hash",
      "guardianFullName",
      "guardianFullName2",
      "guardianFullName3",
      "guardianFullName4",
      "guardianFirstName",
      "guardianLastName",
      "guardian2FirstName",
      "guardian2LastName",
      "guardian3FirstName",
      "guardian3LastName",
      "guardian4FirstName",
      "guardian4LastName",
      "guardianEmail",
      "guardianPhone",
      "guardianEmail2",
      "guardianPhone2",
      "guardianEmail3",
      "guardianPhone3",
      "guardianEmail4",
      "guardianPhone4",
      "photoYear",
      "feesRegistration",
      "feesSeason",
      "accessCampus_startDateTime",
      "accessCampus_endDateTime",
      "accessCampus_validAfterDateTime",
      "accessCampus_invalidAfterDateTime",
      "accessBus_startDateTime",
      "accessBus_endDateTime",
      "accessBus_validAfterDateTime",
      "accessBus_invalidAfterDateTime",
      "accessCafeteria_mode",
      "accessCafeteria_startDateTime",
      "accessCafeteria_endDateTime",
      "accessCafeteria_validAfterDateTime",
      "accessCafeteria_invalidAfterDateTime",
      "accessParking_mode",
      "accessParking_startDateTime",
      "accessParking_endDateTime",
      "accessParking_validAfterDateTime",
      "accessParking_invalidAfterDateTime",
      "accessSocial_startDateTime",
      "accessSocial_endDateTime",
      "accessSocial_validAfterDateTime",
      "accessSocial_invalidAfterDateTime",
      "accessAthletics_startDateTime",
      "accessAthletics_endDateTime",
      "accessAthletics_validAfterDateTime",
      "accessAthletics_invalidAfterDateTime",
      "idValid_startDateTime",
      "idValid_endDateTime",
      "idValid_validAfterDateTime",
      "idValid_invalidAfterDateTime",
      "permissionToLeave_startDateTime",
      "permissionToLeave_endDateTime",
      "permissionToLeave_validAfterDateTime",
      "permissionToLeave_invalidAfterDateTime",
      "permissionFlexSchedule_startDateTime",
      "permissionFlexSchedule_endDateTime",
      "permissionFlexSchedule_validAfterDateTime",
      "permissionFlexSchedule_invalidAfterDateTime",
      "actions",
      "statusAtOrg",
    ];

    return lodash
      .chain(UserService.getFieldMapperColumns(orgType))
      .cloneDeep()
      .filter((field) => !lodash.includes(toBeRemovedFields, field.property))
      .orderBy((field) => lodash.chain(field).get("title").toLower().value())
      .value();
  }

}
export class Individual {
  public static IndividualPhotoStatus = {
    Rejected: 0,
    Approved: 1,
  };

  public _new: boolean = false;
  public certificate: any;
  public _key: string;
  public DBkey: string;
  public accessLevelKey: string;
  public disabled: any;
  ASBMember: string;
  studentID: string;
  public licenseID: string;
  public plicID: string;
  public plicPhotoID: string;
  public photoCropDetails: string;
  public firstName: string;
  public lastName: string;
  public birth: string;
  public license: string;
  public barcode: string;
  public photo: any;
  public photoUrl: string;
  public photoFromCsv: string;
  public lastPhotoFromCsv: string;
  public photoRetake: boolean;
  public hash: string;
  public govtID: string;
  public grade: string;
  public RFID: string;
  public lastBadge: any;
  public lastVisitKey: string;
  public tokens: any;
  public uid: string;
  public isSubUserExist: boolean;
  public flag: any;
  public flagColor: any;
  public flagName: any;
  public notes: any;
  public pointBalance: string;
  public isPrimaryGua: boolean;
  public primaryGuaKey: string;
  public events: any;
  public surveyList: any;
  public photoYearFromCsv: any;

  // school type fields
  public class: string;
  public role: string;
  public teacher: string;

  // conference type fields
  public company: string;
  public groupID: string;
  public phone: string;
  public mobilePhone: string;
  public shareMobilePhone: boolean;
  public email: string;
  public address: string;
  public addressPart1: string;
  public addressPart2: string;
  public postalCode: string;
  public city: string;
  public state: string;
  public country: string;
  public downloads: boolean;
  public specialEvents: boolean;

  // sports team type fields
  public team: string;
  public coach: string;
  public jerseyNumber: string;
  public position: string;
  public weight: string;
  public height1: string;
  public height2: string;
  public division: string;
  public ageClass: string;
  public weightClass: string;

  public firstTime: boolean;
  public canReSignIn: boolean;
  public accountID: boolean;
  public lists: any = {};
  public individualTriggers: any = {};
  public userID: string;
  public timestampCreated: number;
  public timestampUpdated: number;
  public timestamp_PhotoLastUpdated: number;
  public photoUpdated: number;
  public phone_Mobile: string;
  public timestamp_indCreated: number;
  public timestamp_indLastUpdated: number;
  public photoStatus: any;
  public createdFrom: any;

  public group: string;
  public department: string;
  public title: string;
  public section: string;
  public custom1: string;
  public custom2: string;
  public custom3: string;
  public auxID: string;
  public lockerNumber: string;
  public lockerCombination: string;
  public teammatePURL: string;
  public isRejected: boolean;
  public guardianEmail: string;
  public guardianPhone: string;
  public guardianEmail2: string;
  public guardianPhone2: string;
  public guardianEmail3: string;
  public guardianPhone3: string;
  public guardianEmail4: string;
  public guardianPhone4: string;
  public status: string;
  public admissibilityGroup: boolean;
  public idLastGenerated: number;

  public salutation;
  public middleName;
  public nickName;
  public graduationYear;
  public suffix;

  public homeRoom: string;
  public period: string;
  public track: string;
  public schoolDepartment: string;
  public campus: string;
  public lunchPeriod: string;
  public volume: string;
  public optin: boolean;
  public installedPWAat: any;
  public relations: any;
  public indEmailStatus: string;
  public guaEmailStatus: string;
  public mobilePhoneStatus: string;
  public guardianPhoneStatus: string;
  public tempSerialNo: string;
  public yearbookOrdered: boolean;
  public yearbookPaid: boolean;
  public yearbookCollected: boolean;
  // safety cards fields
  public hairColor: string;
  public eyeColor: string;
  public indID_Client: string;
  public fileObj: any;
  public selected: boolean;

  // new sports fields
  public program: string;
  public programSection: string;
  public playStatus: string;
  public paymentStatus: string;
  public bookCode: string;
  public relationshipType: string;
  public primaryContact: any;
  public notificationPreference: string;
  public isFoundOnSCV: boolean;
  public isGuardian: boolean;
  public externalSource: string;
  public lastUpdatedBy: string;
  public actions: any[] = [];
  public guardianFullName: string;
  public guardianFullName2: string;
  public guardianFullName3: string;
  public guardianFullName4: string;
  public guardianFirstName: string;
  public guardianLastName: string;
  public guardian2FirstName: string;
  public guardian2LastName: string;
  public guardian3FirstName: string;
  public guardian3LastName: string;
  public guardian4FirstName: string;
  public guardian4LastName: string;
  public photoYear: string;
  public photos: any;

  public isMainAdmin: boolean;
  public ppAttemptedAt: number;
  public isCountIncrease: boolean;

  public certificateStatus: IndCertStatus;

  // Aeries
  public feesBalance: string;
  public parkingLot: string;
  public isYearBookPurchased: boolean;
  public isLunchPrivilege: boolean;
  public canLeaveCampus: boolean;
  public TG: string;
  public SP: string;

  // Bus Pass
  public accessBus_mode: any;
  public accessBus_startDateTime: number;
  public accessBus_endDateTime: number;
  public accessBus_validAfterDateTime: number;
  public accessBus_invalidAfterDateTime: number;

  // Parking Pass
  public accessParking_mode: any;
  public accessParking_startDateTime: number;
  public accessParking_endDateTime: number;
  public accessParking_validAfterDateTime: number;
  public accessParking_invalidAfterDateTime: number;

  // PermissionFlexSchedule
  public permissionFlexSchedule_mode: any;
  public permissionFlexSchedule_startDateTime: number;
  public permissionFlexSchedule_endDateTime: number;
  public permissionFlexSchedule_validAfterDateTime: number;
  public permissionFlexSchedule_invalidAfterDateTime: number;

  // Campus Access
  public accessCampus_mode: any;
  public accessCampus_startDateTime: number;
  public accessCampus_endDateTime: number;
  public accessCampus_validAfterDateTime: number;
  public accessCampus_invalidAfterDateTime: number;

  // Cafeteria Access
  public accessCafeteria_mode: any;
  public accessCafeteria_startDateTime: number;
  public accessCafeteria_endDateTime: number;
  public accessCafeteria_validAfterDateTime: number;
  public accessCafeteria_invalidAfterDateTime: number;

  // Social Access
  public accessSocial_mode: any;
  public accessSocial_startDateTime: number;
  public accessSocial_endDateTime: number;
  public accessSocial_validAfterDateTime: number;
  public accessSocial_invalidAfterDateTime: number;

  // Athletics Access
  public accessAthletics_mode: any;
  public accessAthletics_startDateTime: number;
  public accessAthletics_endDateTime: number;
  public accessAthletics_validAfterDateTime: number;
  public accessAthletics_invalidAfterDateTime: number;
  
  // Permission To leave
  public permissionToLeave_mode: any;
  public permissionToLeave_startDateTime: number;
  public permissionToLeave_endDateTime: number;
  public permissionToLeave_validAfterDateTime: number;
  public permissionToLeave_invalidAfterDateTime: number;

  // Id Valid
  public idValid_mode: any;
  public idValid_startDateTime: number;
  public idValid_endDateTime: number;
  public idValid_validAfterDateTime: number;
  public idValid_invalidAfterDateTime: number;

 
  public genNewPW: boolean;

  // Field For Yes League (Sportsteam)
  public physicianName: null;
  public physicianStreet: null;
  public physicianCity: null;
  public physicianState: null;
  public physicianZip: null;
  public physicianPhone: null;
  public physicianFax: null;
  public physicianEmail: null;
  public medPrefHospital: null;

  // Medical Conditions
  public medAllergies: null;
  public medConditions: null;
  public medOtherNotes: null;

  // Medical Considerations
  public hasInsurance: null;

  // Medical Insurance
  public insuranceCarrierName: null;
  public insuranceGroup: null;
  public insurancePolicyNumber: null;
  public insuranceGroupNumber: null;
  public insurancePolicyHolderName: null;

  // School For Yes League
  public schoolName: null;
  public schoolDistrict: null;

  // Emergency Contacts
  // Contact 1
  public emergencyCon1Name: null;
  public emergencyCon1Phone: null;
  public emergencyCon1Relationship: null;

  // Contact 2
  public emergencyCon2Name: null;
  public emergencyCon2Phone: null;
  public emergencyCon2Relationship: null;

  public employer: null;
  public feesSeason: any;
  public feesRegistration: any;

  public signature: null;

  public sourceType: string;
  copyInto(obj: any, key?: string) {
    this.plicID = obj.plicID;
    this.plicPhotoID = obj.plicPhotoID;
    this.photoCropDetails = obj.photoCropDetails;
    this.firstName = obj.firstName;
    this.lastName = obj.lastName;
    this.birth = obj.birth;
    this.license = obj.license;
    this.barcode = obj.barcode;
    this.photoUrl = obj.photoUrl;
    this.photoFromCsv = obj.photoFromCsv;
    this.lastPhotoFromCsv = obj.lastPhotoFromCsv;
    this.photoRetake = obj.photoRetake;
    this.hash = obj.hash;
    this.flag = obj.flag;
    this.flagColor = obj.flagColor ? obj.flagColor : null;
    this.flagName = obj.flagName ? obj.flagName : null;
    this.notes = obj.notes;
    this.pointBalance = obj.pointBalance;
    this.isPrimaryGua =
      obj && obj.hasOwnProperty("isPrimaryGua") ? obj.isPrimaryGua : false;
    this.primaryGuaKey = obj.primaryGuaKey;
    this.events = obj.events ? obj.events : null;
    this.photoYearFromCsv = obj.photoYearFromCsv ? obj.photoYearFromCsv : null;

    this.uid = obj.uid;
    this.isSubUserExist = obj.isSubUserExist;
    this.certificate = obj.certificate || null;

    this.accessLevelKey = obj.accessLevelKey || null;
    // school type fields
    this.class = obj.class;
    this.role = obj.role;
    this.teacher = obj.teacher;
    this.isGuardian = obj.hasOwnProperty("isGuardian") ? obj.isGuardian : false;
    this.guardianFullName = obj.guardianFullName;
    this.guardianFullName2 = obj.guardianFullName2;
    this.guardianFullName3 = obj.guardianFullName3;
    this.guardianFullName4 = obj.guardianFullName4;
    this.guardianFirstName = obj.guardianFirstName;
    this.guardianLastName = obj.guardianLastName;
    this.guardian2FirstName = obj.guardian2FirstName;
    this.guardian2LastName = obj.guardian2LastName;
    this.guardian3FirstName = obj.guardian3FirstName;
    this.guardian3LastName = obj.guardian3LastName;
    this.guardian4FirstName = obj.guardian4FirstName;
    this.guardian4LastName = obj.guardian4LastName;
    this.photoYear = obj.photoYear;
    this.photos = obj.photos;

    // conference type fields
    this.company = obj.company;
    this.groupID = obj.groupID;
    this.phone = obj.phone;
    this.shareMobilePhone = obj.shareMobilePhone;
    this.mobilePhone = obj.mobilePhone;
    this.email = obj.email;
    this.address = obj.address;
    this.addressPart1 = obj.addressPart1;
    this.addressPart2 = obj.addressPart2;
    this.postalCode = obj.postalCode;
    this.city = obj.city;
    this.state = obj.state;
    this.country = obj.country;
    this.downloads = obj.downloads;
    this.specialEvents = obj.specialEvents;

    // sports team type fields
    this.team = obj.team;
    this.coach = obj.coach;
    this.jerseyNumber = obj.jerseyNumber;
    this.position = obj.position;
    this.weight = obj.weight;
    this.height1 = obj.height1;
    this.height2 = obj.height2;
    this.division = obj.division;
    this.ageClass = obj.ageClass;
    this.weightClass = obj.weightClass;

    this.lists = obj.lists;
    this.individualTriggers = obj.individualTriggers;
    this.firstTime = obj.firstTime;
    this.canReSignIn = obj.canReSignIn;

    this.lastBadge = obj.lastBadge;
    this.lastVisitKey = obj.lastVisitKey;
    this.tokens = obj.tokens;

    this.timestampCreated = obj.timestampCreated;
    this.timestampUpdated = obj.timestampUpdated;
    this.photoUpdated = obj.photoUpdated;

    this.userID = obj.uid;
    this.phone_Mobile = obj.mobilePhone;
    this.timestamp_indCreated = obj.timestampCreated;
    this.timestamp_indLastUpdated = obj.timestampUpdated;
    this.timestamp_PhotoLastUpdated = obj.photoUpdated;
    this.DBkey = key;
    this.licenseID = obj.studentID;
    this.ASBMember =
      obj && obj.hasOwnProperty("ASBMember") ? obj.ASBMember : false;

    this.admissibilityGroup =
      obj && obj.hasOwnProperty("admissibilityGroup")
        ? obj.admissibilityGroup
        : null;
    this.studentID = obj && obj.studentID ? obj.studentID : "";
    this._new = false;
    this._key = key;
    this.disabled = obj.disabled;
    this.govtID = obj.license;
    this.grade = obj.class || "";
    this.RFID = obj.RFID;
    this.photoStatus = obj.photoStatus === "" ? null : obj.photoStatus;
    this.createdFrom = obj.createdFrom;

    this.group = obj.group;
    this.department = obj.department;
    this.title = obj.title;
    this.section = obj.section;
    this.custom1 = obj.custom1;
    this.custom2 = obj.custom2;
    this.custom3 = obj.custom3;
    this.auxID = obj.auxID;
    this.lockerNumber = obj.lockerNumber;
    this.lockerCombination = obj.lockerCombination;
    this.teammatePURL = obj.teammatePURL;
    this.guardianEmail = obj.guardianEmail;
    this.guardianPhone = obj.guardianPhone;
    this.guardianEmail2 = obj.guardianEmail2;
    this.guardianPhone2 = obj.guardianPhone2;
    this.guardianEmail3 = obj.guardianEmail3;
    this.guardianPhone3 = obj.guardianPhone3;
    this.guardianEmail4 = obj.guardianEmail4;
    this.guardianPhone4 = obj.guardianPhone4;
    this.status = obj.status;
    this.idLastGenerated = obj.idLastGenerated;

    this.salutation = obj.salutation;
    this.suffix = obj.suffix;
    this.middleName = obj.middleName;
    this.nickName = obj.nickName;
    this.graduationYear = obj.graduationYear;
    this.homeRoom = obj.homeRoom;
    this.period = obj.period;
    this.track = obj.track;
    this.schoolDepartment = obj.schoolDepartment;
    this.campus = obj.campus;
    this.lunchPeriod = obj.lunchPeriod;
    this.yearbookOrdered = obj.yearbookOrdered || false;
    this.yearbookPaid = obj.yearbookPaid || false;
    this.yearbookCollected = obj.yearbookCollected || false;
    this.volume = obj.volume;
    this.optin = obj && obj.hasOwnProperty("optin") ? obj.optin : true;
    this.installedPWAat = obj.installedPWAat;
    this.relations = obj.relations;
    this.indEmailStatus = obj.indEmailStatus;
    this.guaEmailStatus = obj.guaEmailStatus;
    this.mobilePhoneStatus = obj.mobilePhoneStatus;
    this.guardianPhoneStatus = obj.guardianPhoneStatus;
    this.tempSerialNo = obj.tempSerialNo || "";
    this.hairColor = obj.hairColor || null;
    this.eyeColor = obj.eyeColor || null;
    this.indID_Client = obj.indID_Client || "";
    this.fileObj = obj.fileObj || "";
    this.selected = obj.hasOwnProperty("selected") ? obj.selected : false;

    this.program = obj.program;
    this.programSection = obj.programSection;
    this.playStatus = obj.playStatus;
    this.paymentStatus = obj.paymentStatus;
    this.bookCode = obj.bookCode;
    this.primaryContact = obj.primaryContact;
    this.notificationPreference = obj.notificationPreference;
    this.relationshipType = obj.relationshipType;
    this.isFoundOnSCV = obj.isFoundOnSCV || false;
    this.isMainAdmin = obj.isMainAdmin || false;
    this.ppAttemptedAt = obj.ppAttemptedAt;
    this.isCountIncrease = obj.isCountIncrease || false;

    this.certificateStatus = obj.certificateStatus;
    // Aeries
    this.feesBalance = obj.feesBalance || "";
    this.parkingLot = obj.parkingLot || "";
    this.isYearBookPurchased = obj.isYearBookPurchased || false;
    this.canLeaveCampus = obj.canLeaveCampus || false;
    this.isLunchPrivilege = obj.isLunchPrivilege || false;
    this.TG = obj.TG || "";
    this.SP = obj.SP || "";

    // Bus Access
    this.accessBus_mode = obj.accessBus_mode || TimeVaryingMethod.NO;
    this.accessBus_startDateTime = obj.accessBus_startDateTime || "";
    this.accessBus_endDateTime = obj.accessBus_endDateTime || "";
    this.accessBus_validAfterDateTime = obj.accessBus_validAfterDateTime || "";
    this.accessBus_invalidAfterDateTime = obj.accessBus_invalidAfterDateTime || "";

    // Parking Access
    this.accessParking_mode = obj.accessParking_mode || TimeVaryingMethod.NO;
    this.accessParking_startDateTime = obj.accessParking_startDateTime || "";
    this.accessParking_endDateTime = obj.accessParking_endDateTime || "";
    this.accessParking_validAfterDateTime = obj.accessParking_validAfterDateTime || "";
    this.accessParking_invalidAfterDateTime = obj.accessParking_invalidAfterDateTime || ""

    // PermissionFlexSchedule Access
    this.permissionFlexSchedule_mode = obj.permissionFlexSchedule_mode || TimeVaryingMethod.NO;
    this.permissionFlexSchedule_startDateTime = obj.permissionFlexSchedule_startDateTime || "";
    this.permissionFlexSchedule_endDateTime = obj.permissionFlexSchedule_endDateTime || "";
    this.permissionFlexSchedule_validAfterDateTime = obj.permissionFlexSchedule_validAfterDateTime || "";
    this.permissionFlexSchedule_invalidAfterDateTime = obj.permissionFlexSchedule_invalidAfterDateTime || ""
    
    // Campus Access
    this.accessCampus_mode = obj.accessCampus_mode || TimeVaryingMethod.YES;
    this.accessCampus_startDateTime = obj.accessCampus_startDateTime || "";
    this.accessCampus_endDateTime = obj.accessCampus_endDateTime || "";
    this.accessCampus_validAfterDateTime = obj.accessCampus_validAfterDateTime || "";
    this.accessCampus_invalidAfterDateTime = obj.accessCampus_invalidAfterDateTime || "";

    // Cafeteria Access
    this.accessCafeteria_mode = obj.accessCafeteria_mode || TimeVaryingMethod.YES,
    this.accessCafeteria_startDateTime = obj.accessCafeteria_startDateTime || "",
    this.accessCafeteria_endDateTime = obj.accessCafeteria_endDateTime || "",
    this.accessCafeteria_validAfterDateTime = obj.accessCafeteria_validAfterDateTime || "",
    this.accessCafeteria_invalidAfterDateTime = obj.accessCafeteria_invalidAfterDateTime || "",

    // Social Access
    this.accessSocial_mode = obj.accessSocial_mode || TimeVaryingMethod.YES,
    this.accessSocial_startDateTime = obj.accessSocial_startDateTime || "",
    this.accessSocial_endDateTime = obj.accessSocial_endDateTime || "",
    this.accessSocial_validAfterDateTime = obj.accessSocial_validAfterDateTime || "",
    this.accessSocial_invalidAfterDateTime = obj.accessSocial_invalidAfterDateTime || "",

    // Athletics Access
    this.accessAthletics_mode = obj.accessAthletics_mode || TimeVaryingMethod.YES,
    this.accessAthletics_startDateTime = obj.accessAthletics_startDateTime || "",
    this.accessAthletics_endDateTime = obj.accessAthletics_endDateTime || "",
    this.accessAthletics_validAfterDateTime = obj.accessAthletics_validAfterDateTime || "",
    this.accessAthletics_invalidAfterDateTime = obj.accessAthletics_invalidAfterDateTime || "",

    // Permission to leave
    this.permissionToLeave_mode = obj.permissionToLeave_mode || TimeVaryingMethod.NO,
    this.permissionToLeave_startDateTime = obj.permissionToLeave_startDateTime || "",
    this.permissionToLeave_endDateTime = obj.permissionToLeave_endDateTime || "",
    this.permissionToLeave_validAfterDateTime = obj.permissionToLeave_validAfterDateTime || "",
    this.permissionToLeave_invalidAfterDateTime = obj.permissionToLeave_invalidAfterDateTime || "",

    // Id Valid
    this.idValid_mode = obj.idValid_mode || TimeVaryingMethod.YES,
    this.idValid_startDateTime = obj.idValid_startDateTime || "",
    this.idValid_endDateTime = obj.idValid_endDateTime || "",
    this.idValid_validAfterDateTime = obj.idValid_validAfterDateTime || "",
    this.idValid_invalidAfterDateTime = obj.idValid_invalidAfterDateTime || "",

    
    this.genNewPW = obj.genNewPW || false;

    // Field For Yes League (Sportsteam)
    this.physicianName = obj.physicianName || "";
    this.physicianStreet = obj.physicianStreet || "";
    this.physicianCity = obj.physicianCity || "";
    this.physicianState = obj.physicianState || "";
    this.physicianZip = obj.physicianZip || "";
    this.physicianPhone = obj.physicianPhone || "";
    this.physicianFax = obj.physicianFax || "";
    this.physicianEmail = obj.physicianEmail || "";
    this.medPrefHospital = obj.medPrefHospital || "";

    // Medical Conditions
    this.medAllergies = obj.medAllergies || "";
    this.medConditions = obj.medConditions || "";
    this.medOtherNotes = obj.medOtherNotes || "";

    // Medical Considerations
    this.hasInsurance = obj.hasOwnProperty("hasInsurance")
      ? obj.hasInsurance
      : false;

    // Medical Insurance
    this.insuranceCarrierName = obj.insuranceCarrierName || "";
    this.insuranceGroup = obj.insuranceGroup || "";
    this.insurancePolicyNumber = obj.insurancePolicyNumber || "";
    this.insuranceGroupNumber = obj.insuranceGroupNumber || "";
    this.insurancePolicyHolderName = obj.insurancePolicyHolderName || "";

    // School For Yes League
    this.schoolName = obj.schoolName || "";
    this.schoolDistrict = obj.schoolDistrict || "";

    // Emergency Contacts
    // Contact 1
    this.emergencyCon1Name = obj.emergencyCon1Name || "";
    this.emergencyCon1Phone = obj.emergencyCon1Phone || "";
    this.emergencyCon1Relationship = obj.emergencyCon1Relationship || "";

    // Contact 2
    this.emergencyCon2Name = obj.emergencyCon2Name || "";
    this.emergencyCon2Phone = obj.emergencyCon2Phone || "";
    this.emergencyCon2Relationship = obj.emergencyCon2Relationship || "";

    this.employer = obj.employer || "";
    this.feesRegistration = obj.hasOwnProperty("feesRegistration")
      ? obj.feesRegistration
      : "";
    this.feesSeason = obj.hasOwnProperty("feesSeason") ? obj.feesSeason : "";

    this.signature = obj.signature || "";

    this.sourceType = obj.sourceType || null;
    this.externalSource = obj.externalSource || null;
    this.actions = !lodash.isEmpty(obj.actions) ? obj.actions : null;
    this.lastUpdatedBy = obj.lastUpdatedBy || null;
  }

  clearInternal() {
    this._new = null;
    this._key = null;

    lodash.forOwn(this, (value, key) => {
      if (this[key] === undefined) {
        this[key] = null;
      }
    });
  }
  static replaceSpecialChar(str) {
    if (str) {
      str = str.replace(/[\u{0080}-\u{FFFF}]/gu, "");
    }
    return str;
  }
  static replaceOtherSpecialChar(str) {
    if (str) {
      str = str.replace(/[^a-zA-Z.-_0-9]/g, "");
      str = str.replace(/\//g, "");
    }
    return str;
  }
  static createHash(firstName: string, lastName: string, orgID: string) {
    function guid(len) {
      var buf = [],
        chars = "0123456789",
        charlen = chars.length,
        length = len || 32;
      for (var i = 0; i < length; i++) {
        buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
      }
      return buf.join("");
    }
    function getHashFromString(str) {
      var hash = 0,
        i,
        chr;
      if (str.length === 0) return hash;
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash >>> 0;
    }

    let str: any =
      firstName.replace(/ /g, "").trim().toUpperCase() +
      "_" +
      lastName.replace(/ /g, "").trim().toUpperCase();
    str = str.replace(/[\u{0080}-\u{FFFF}]/gu, "");
    if (str.length > 50) {
      str = str.substring(0, 50);
    }

    const toHash =
      str + "_" + orgID + "_" + new Date().getTime() + "_" + guid(3);
    return str + "_" + getHashFromString(toHash);
  }

}
export class IndividualObj {
  firstName: string;
  lastName: string;
  company: string;
  photoUrl: string;
  role: string;
  teacher: string;
}

export enum IndActions {
  ResetPassword = "resetPassword",
  SendMessage = "sendMessage",
  CapturePhoto = "capturePhoto",
  OutputID = "outputID",
  PersonalHomePage = "personalHomePage",
  SendPersonalHomePageLink = "sendPersonalHomePageLink",
  Delete = "delete",
  AssignPass = "assignPass",
  AddBehaviouralIncident = "addBehaviouralIncident",
  MarkTaskCompleted = "markTaskCompleted",
  RemoveImage = "removeImage",
  AddEventTicket = "addEventTicket",
  ReceptionLogs = "receptionLogs",
  SexOffenderLookup = "sexOffenderLookup",
  SetFlag = "setFlag",
  Archive = "archive",
  AdminPanel = "adminPanel",
  SetPassword = "setPassword",
}

@Injectable({
  providedIn: 'root',
})
export class IndividualApiService {
  constructor(
    private db: AngularFireDatabase,
    private http: HttpClient,
    private commonFun: CommonServiceService,
    private cacheService: CacheService,
    private apiHelperService: ApiHelperService
  ) { }

  // --- convert ind data to full individual object
  getFullInd(individual: any) {
    let indID = lodash.get(individual, "key") || lodash.get(individual, "_key");

    let fullInd = new Individual();
    fullInd._new = false;
    fullInd.copyInto(individual, indID);
    return fullInd;
  }

  // --- get individual data
  async getIndData(
    orgID: string,
    indID: string,
    propsToReturn: string[] = ["all"],
    full: boolean = false,
    useCache?: boolean,
    authDependencies?: AuthDependency[]
  ) {
    if (!orgID || !indID || !propsToReturn) throw new Error("Params missing!");

    // --- prepare cache path
    let cachePath = `${orgID}/${indID}/`;
    cachePath += this.commonFun.getCachePathForProps(propsToReturn);

    let indData: any;

    // --- read from cache
    if (useCache) {
      let cachedData = this.cacheService.get(CacheKeys.IndData, cachePath);
      if (cachedData) indData = cachedData.value;
    }

    // --- make http request if cache data isn't available
    if (!indData) {
      let reqBody = { orgID, indID, propsToReturn, authDependencies };

      let response: any = await this.apiHelperService.postToCloudFn(
        CloudFnNames.getIndividualData,
        reqBody
      );
      response = lodash.get(response, "result");
      if (!response || response.success != 1)
        throw new Error(lodash.get(response, "message"));
      indData = lodash.get(response, "data");

      // --- set cache
      this.cacheService.set(CacheKeys.IndData, cachePath, indData);
    }

    if (full) indData = this.getFullInd(indData);

    return indData;
  }

  async getIndFromFireBase(orgId, indId: string, full: boolean = false) {
    try {
      const snapshot = await this.db.list(`individuals/${orgId}/${indId}`).query.once('value');
      let indData = snapshot.val();
      if (full) indData = this.getFullInd(indData);
      return indData
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  }

  // --- set individual password
  async setIndPW(orgID: string, indID: string, newPW: string, token?: string) {
    // --- check password validation
    if (!this.commonFun.isPwValid(newPW)) throw new Error("Invalid password!");

    let setIndPWRes = await this.apiHelperService.postToCloudFn(
      CloudFnNames.setIndPW,
      {
        orgID,
        indID,
        newPW,
        token,
      }
    );
    return lodash.get(setIndPWRes, "result.data");
  }

  getOne(orgID: string, indID: string) {
    return this.db
      .object<IndividualObj>(`/${collection}/${orgID}/${indID}`)
      .valueChanges()
      .pipe(first());
  }

  async getOneFull(orgID: string, indID: string) {
    if (!orgID || !indID) return null;

    let snapshot = await this.db
      .object(`individuals/${orgID}/${indID}`)
      .query.once("value");
    let indData = snapshot.val();
    if (!indData) return null;

    let individual = new Individual();
    individual._new = false;
    individual.copyInto(indData, indID);
    return individual;
  }

  getOneFromIndHash(orgID: string, indHash: string) {
    return new Observable((observer) => {
      let studentSub = this.db
        .list(`/individuals/${orgID}`, (ref) =>
          ref.orderByChild("hash").equalTo(indHash)
        )
        .snapshotChanges()
        .subscribe((res: any) => {
          studentSub.unsubscribe();
          if (!res || res.length == 0) {
            observer.next(null);
            observer.complete();
          }
          lodash.each(res, (entry) => {
            let exists: any = new Individual();
            exists._new = false;
            exists.copyInto(entry.payload.val(), entry.payload.key);
            observer.next(exists);
            observer.complete();
          });
        });
    });
  }

  getAllIndByOrg(orgID: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let listSub = this.db
        .list(`/individuals/${orgID}`)
        .snapshotChanges()
        .subscribe((list) => {
          listSub.unsubscribe();
          let indList: any[] = [];
          lodash.each(list, (entry) => {
            let exists: any = new Individual();
            exists._new = false;
            exists.copyInto(entry.payload.val(), entry.payload.key);
            indList.push(exists);
          });
          resolve(indList);
        });
    });
  }
  // --- get all relations of given individual
  async getAllRelations(orgID: any, indId: any, callback: Function) {
    let readRelationsPromises = [];
    readRelationsPromises.push(
      this.db
        .list(`relations/${orgID}`)
        .query.orderByChild("indId")
        .equalTo(indId)
        .once("value")
    );
    readRelationsPromises.push(
      this.db
        .list(`relations/${orgID}`)
        .query.orderByChild("relativeIndId")
        .equalTo(indId)
        .once("value")
    );

    try {
      let readRelationsPromisesRes = await Promise.all(readRelationsPromises);
      readRelationsPromisesRes = lodash.map(
        readRelationsPromisesRes,
        (relationsSnapshot) => relationsSnapshot.val()
      ); // --- convert snapshots to values
      let relationsIndId = readRelationsPromisesRes[0];
      let relationsRelativeIndId = readRelationsPromisesRes[1];
      relationsIndId = lodash.map(
        relationsIndId,
        (relationData: any, relationKey) => {
          return { ...relationData, relationKey };
        }
      );
      relationsRelativeIndId = lodash.map(
        relationsRelativeIndId,
        (relationData: any, relationKey) => {
          return { ...relationData, relationKey };
        }
      );
      let mergedRelations = lodash.union(
        relationsIndId,
        relationsRelativeIndId
      );
      if (callback) callback(mergedRelations);
      return mergedRelations;
    } catch (e) {
      if (callback) callback(null);
      return null;
    }
  }

  /**
   * get all individual relations of organizations
   * @param orgID organization id   
   * @return all relations
   */
  async getAllRelationOfOrg(orgId) {
    let relationData = (await this.db.object(`relations/${orgId}`).query.once('value')).val();
    return lodash.isNil(relationData) ? [] : this.commonFun.convertObjToArr(relationData);
  }

  /**
   * get all parents of individual
   * @param orgID organization id
   * @param ind current individual
   * @param indList organization indList
   * @param relationList individuals relation list of organization
   * @return current ind all parents list
   */
  async getAllRelationsOfInd(ind, indList, relationList, orgId, isGetIndRelation: boolean = true) {
    if (relationList.length == 0 && isGetIndRelation) {
      relationList = await this.getAllRelationOfOrg(orgId);
    }

    let indParent = lodash.filter(
      relationList, 
      (parent: any) => (
        (parent.relation == 'dependents' && parent.relativeIndId == ind.DBkey) ||
        (parent.relation == 'parents' && parent.indId == ind.DBkey)
      )
    );

    let parentId = [];
    lodash.forEach(indParent, (parent: any) => {
      if (parent.relation == 'dependents') parentId.push(parent.indId);
      if (parent.relation == 'parents') parentId.push(parent.relativeIndId);
    });

    let parentList = [];
    lodash.forEach(parentId, id => {
      let parent = lodash.filter(indList, (ind: any) => ind.DBkey == id)[0];
      if (lodash.isNil(parent)) return;
      parentList.push(parent);
    });

    return parentList || [];
  }

  async addRelations(orgID: any, existingData: any) {
    return await this.db.object(`/relations/${orgID}/`).update(existingData);
  }

  /**
   * bulk update individuals node of particular organization
   * @param orgID organization id
   * @param updateObj update object of individuals
   */
  async bulkUpdateIndividuals(
    orgID,
    updateObj,
    deletePropFun?,
    skipObjCleaning?: boolean
  ) {
    console.log('updateObj: ', lodash.cloneDeep(updateObj));
    if (!orgID || !updateObj) throw Error("Params missing!");

    updateObj = lodash.cloneDeep(updateObj);

    if (!skipObjCleaning) {
      // --- remove undefined/null/"" value props
      lodash.each(updateObj, (indData, indID) => {
        lodash.each(indData, (val, key) => {
          if (deletePropFun) {
            if (deletePropFun(val)) delete updateObj[indID][key];
          } else if (lodash.isNil(val) || val == "" || val == "undefined")
            delete updateObj[indID][key];
        });
      });
    }

    await this.db.object(`individuals/${orgID}`).update(updateObj);
  }

  // --- move s3 image from one location to other
  async moveS3Image(fromPath, toPath, preventOriginalImgDeletion = false) {
    let reqBody = {
      type: "moveS3File",
      fromPath,
      toPath,
      preventOriginalImgDeletion,
    };
    let [response, err] = await this.commonFun.executePromise(
      this.http
        .post(`${environment.awsImageUpload.endPoint}/s3`, reqBody, {
          headers: { "x-api-key": environment.awsImageUpload.xApiKey },
        })
        .pipe(take(1))
        .toPromise()
    );
    if (err) {
      return false;
    }
    return true;
  }


  /**
   * @returns true if ind has some access level (including admin), false otherwise
   */
  isSubUser(indData: any) {
    if (!indData) return false;
    if (!indData.accessLevelKey) return false;
    if (lodash.toLower(indData.accessLevelKey) == "ppo") return false;
    return true;
  }

  async getRoleBasedIndCount(orgId: string) {
    let reqBody = { orgId };
    let response = await this.apiHelperService.postToCloudFn(
      CloudFnNames.getRoleBasedIndCount,
      reqBody
    );
    return lodash.get(response, "result.data");
  }

  // --- wide search individuals (eg. studio searches for inds under all child orgs)
  async wideSearchInds(
    orgIDs: string[],
    userInputs: { name?: string; email?: string },
    propsToReturn: string[] = ["firstName", "lastName", "orgID"]
  ) {
    if (
      !orgIDs ||
      lodash.size(orgIDs) == 0 ||
      !userInputs ||
      lodash.isEmpty(userInputs)
    )
      throw new Error("Params missing!");

    let payload: any = {
      orgIDs,
      userInputs,
      propsToReturn: propsToReturn,
    };

    let matchingIndsRes = await this.apiHelperService.postToCloudFn(
      CloudFnNames.wideSearchInds,
      payload
    );
    return lodash.get(matchingIndsRes, "result.data");
  }

  // --- search individuals
  async searchInds(
    orgID: string,
    indSearchType: IndSearchType,
    userInput: string,
    propsToReturn: string[] = ["key"],
    role?: string,
    restrictions?: any
  ) {
    if (!orgID || !indSearchType || !userInput)
      throw new Error("Params missing!");

    let payload: any = {
      orgID: orgID,
      type: indSearchType,
      userInput: userInput,
      propsToReturn: propsToReturn
    };
    if (role) payload.role = role;
    if (restrictions) payload.restrictions = restrictions;

    let [matchingIndsRes, matchingIndsErr] = await this.commonFun.executePromise(this.apiHelperService.postToCloudFn(
      CloudFnNames.searchInds,
      payload
    ));
    if (matchingIndsErr) {
      throw matchingIndsErr;
    }
    return lodash.get(matchingIndsRes, "result.data");
  }

  // --- get individual email (to use in authentication)
  async getIndEmail(orgID: string, indID: string, password: string, isAutoLogin: boolean = false) {
    if (!orgID || !indID || !password) throw new Error("Params missing!");

    // --- get ind email to sign in
    let reqBody = { orgID, indID, password, isAutoLogin };
    let response = await this.apiHelperService.postToCloudFn(
      CloudFnNames.getIndEmail,
      reqBody
    );
    return lodash.get(response, "result.data.email");
  }

  async addNewIndividual(orgID, indID, individual, authDependencies?) {
    if (!orgID || !individual || !indID) {
      throw new Error('Params missing!');
    }
    try {
      await this.apiHelperService.postToCloudFn(CloudFnNames.addInd, {
        orgID,
        individual,
        indID,
        authDependencies,
      });
      return true;
    } catch (error) {
      throw error;
    }
  }

  async addIndividualInDb(orgId: string, indKey: string, indObj: any) {
    if (!orgId || !indKey) {
      throw new Error('Params missing!');
    }
    try {
      await this.db
        .object(`/individuals/${orgId}/${indKey}`)
        .set(indObj);
    } catch (error) {
      throw new Error(error)
    }
  }

  photoStatusMap = { "not yet reviewed": "", approved: 1, rejected: 0 };
  sportFieldProgamList = [
    { id: "Football", name: "Football" },
    { id: "Spirit", name: "Spirit" },
    { id: "Board", name: "Board" },
  ];
  sportFieldProgamSectionList = [
    { id: "none", name: "None" },
    { id: "Cheer", name: "Cheer" },
    { id: "Step", name: "Step" },
    { id: "Dance", name: "Dance" },
  ];
  sportFieldPlayStatusList = [
    { id: "OK", name: "OK" },
    { id: "suspended", name: "Suspended" },
    { id: "Expelled", name: "Expelled" },
  ];
  sportFieldPaymentStatusList = [
    { id: "Outstanding Payment", name: "Outstanding Payment" },
    { id: "Paid", name: "Paid" },
    { id: "Other", name: "Other" },
  ];

  formatValue(value: any, propKey: string, toFormat: "display" | "dbValue" , indObj?: any ) {
    let dbValue;
    let truthyValues;
    let falsyValues;
    switch (lodash.toLower(propKey)) {
      case "asbmember":
      case "boolean":
        truthyValues = ["true", "yes", "y"];
        dbValue = lodash.includes(truthyValues, lodash.toLower(value));
        if (toFormat == "dbValue") return dbValue;
        else if (toFormat == "display") return dbValue ? "Yes" : "No";
        break;

      case "yearbookOrdered":
      case "yearbookPaid":
      case "yearbookCollected":
        dbValue = JSON.parse(lodash.find(this.commonFun.yearbookArr, (item: any) => item.value == value).value);
        if (toFormat == "dbValue") return dbValue;
        else if (toFormat == "display") {
          return dbValue ? "Yes" : "No";
        }
        break;

      case "photostatus":
        let validDBValues = [0, 1, "0", "1", ""];

        // --- try to extract dbValue from given input
        dbValue = lodash.includes(validDBValues, value) ? value : null;
        if (lodash.isNil(dbValue)) {
          value = lodash.get(this.photoStatusMap, lodash.toLower(value), value);
        }
        dbValue = lodash.includes(validDBValues, value) ? value : "";

        // --- convert dbValue to number if possible
        if (!lodash.isNil(dbValue) && dbValue !== "") {
          dbValue = lodash.toNumber(dbValue);
        }

        if (toFormat == "dbValue") return dbValue;
        else if (toFormat == "display") {
          if (dbValue === 0) return "Rejected";
          if (dbValue === 1) return "Approved";
          return "Awaiting for approval";
        }
        break;

      case "accesscampus_mode":
      case "accesscafeteria_mode":
      case "accessbus_mode":
      case "idvalid_mode":
      case "accessparking_mode":
      case "accesssocial_mode":
      case "accessathletics_mode":
      case "permissiontoleave_mode":
      case "permissionflexschedule_mode":
        truthyValues = ["true", "yes", "y", 1, "1"];
        falsyValues = ["false", "no", "n", 2, "2"];
        dbValue = this.getDbValOfDynamicParameters(lodash.toLower(String(value)));

        if (toFormat == "dbValue") return dbValue;
        else if (toFormat == "display") return this.getDisplayValOfDynamicParameters(value);
        break;

      case "program":
      case "programsection":
      case "playstatus":
      case "paymentstatus":
        let allowedValues;
        switch (lodash.toLower(propKey)) {
          case "program":
            allowedValues = this.sportFieldProgamList;
            break;
          case "programsection":
            allowedValues = this.sportFieldProgamSectionList;
            break;
          case "playstatus":
            allowedValues = this.sportFieldPlayStatusList;
            break;
          case "paymentstatus":
            allowedValues = this.sportFieldPaymentStatusList;
            break;
        }

        let matchingItem = lodash.find(
          allowedValues,
          (item) =>
            lodash.toLower(item.name).trim() == lodash.toLower(value).trim()
        );
        dbValue = lodash.get(matchingItem, "id");
        if (toFormat == "dbValue") return dbValue;
        else if (toFormat == "display") return lodash.get(matchingItem, "name");
        break;

      case "accesscafeteria_enddatetime":
      case "accesscafeteria_startdatetime":
      case "accesscafeteria_invalidafterdatetime":
      case "accesscafeteria_validafterdatetime":
        if (toFormat == "dbValue") return value;
        else if (toFormat == "display" && !lodash.isEmpty(indObj) && value) {
          if (indObj.accessCafeteria_mode != TimeVaryingMethod.YES && indObj.accessCafeteria_mode != TimeVaryingMethod.NO) {
            if (
              indObj.accessCafeteria_mode >= TimeVaryingMethod.YESINTIME &&
              indObj.accessCafeteria_mode <= TimeVaryingMethod.NOAFTERTIME
            ) {
              return moment(Number(value), 'x').format('HH:mm');
            } else {
              return moment(Number(value), 'x').format("YYYY/MM/DD HH:mm");
            }
          }
        }
        else if (toFormat == "display" && lodash.isEmpty(indObj)) return value;
        break;

      case "accessbus_startdatetime":
      case "accessbus_enddatetime":
      case "accessbus_validafterdatetime":
      case "accessbus_invalidafterdatetime":
        if (toFormat == "dbValue") return value;
        else if (toFormat == "display" && !lodash.isEmpty(indObj) && value) {
          if (indObj.accessBus_mode != TimeVaryingMethod.YES && indObj.accessBus_mode != TimeVaryingMethod.NO) {
            if (
              indObj.accessBus_mode >= TimeVaryingMethod.YESINTIME &&
              indObj.accessBus_mode <= TimeVaryingMethod.NOAFTERTIME
            ) {
              return moment(Number(value), 'x').format('HH:mm');
            } else {
              return moment(Number(value), 'x').format("YYYY/MM/DD HH:mm");
            }
          }
        }
        else if (toFormat == "display" && lodash.isEmpty(indObj)) return value;
        break;

      case "accesscampus_enddatetime":
      case "accesscampus_startdatetime":
      case "accesscampus_invalidafterdatetime":
      case "accesscampus_validafterdatetime":
        if (toFormat == "dbValue") return value;
        else if (toFormat == "display" && !lodash.isEmpty(indObj) && value) {
          if (indObj.accessCampus_mode != TimeVaryingMethod.YES && indObj.accessCampus_mode != TimeVaryingMethod.NO) {
            if (
              indObj.accessCampus_mode >= TimeVaryingMethod.YESINTIME &&
              indObj.accessCampus_mode <= TimeVaryingMethod.NOAFTERTIME
            ) {
              return moment(Number(value), 'x').format('HH:mm');
            } else {
              return moment(Number(value), 'x').format("YYYY/MM/DD HH:mm");
            }
          }
        }
        else if (toFormat == "display" && lodash.isEmpty(indObj)) return value;
        break;

      case "idvalid_enddatetime":
      case "idvalid_startdatetime":
      case "idvalid_invalidafterdatetime":
      case "idvalid_validafterdatetime":
        if (toFormat == "dbValue") return value;
        else if (toFormat == "display" && !lodash.isEmpty(indObj) && value) {
          if (indObj.idValid_mode != TimeVaryingMethod.YES && indObj.idValid_mode != TimeVaryingMethod.NO) {
            if (
              indObj.idValid_mode >= TimeVaryingMethod.YESINTIME &&
              indObj.idValid_mode <= TimeVaryingMethod.NOAFTERTIME
            ) {
              return moment(Number(value), 'x').format('HH:mm');
            } else {
              return moment(Number(value), 'x').format("YYYY/MM/DD HH:mm");
            }
          }
        }
        else if (toFormat == "display" && lodash.isEmpty(indObj)) return value;
        break;

      case "permissiontoleave_enddatetime":
      case "permissiontoleave_startdatetime":
      case "permissiontoleave_validafterdatetime":
      case "permissiontoleave_invalidafterdatetime":
        if (toFormat == "dbValue") return value;
        else if (toFormat == "display" && !lodash.isEmpty(indObj) && value) {
          if (indObj.permissionToLeave_mode != TimeVaryingMethod.YES && indObj.permissionToLeave_mode != TimeVaryingMethod.NO) {
            if (
              indObj.permissionToLeave_mode >= TimeVaryingMethod.YESINTIME &&
              indObj.permissionToLeave_mode <= TimeVaryingMethod.NOAFTERTIME
            ) {
              return moment(Number(value), 'x').format('HH:mm');
            } else {
              return moment(Number(value), 'x').format("YYYY/MM/DD HH:mm");
            }
          }
        }
        else if (toFormat == "display" && lodash.isEmpty(indObj)) return value;
        break;

      case "permissionflexschedule_enddatetime":
      case "permissionflexschedule_startdatetime":
      case "permissionflexschedule_validafterdatetime":
      case "permissionflexschedule_invalidafterdatetime":
        console.log('value: ', value);
        console.log('selected key: ', propKey);
        if (toFormat == "dbValue") return value;
        else if (toFormat == "display" && !lodash.isEmpty(indObj) && value) {
          if (indObj.permissionFlexSchedule_mode != TimeVaryingMethod.YES && indObj.permissionFlexSchedule_mode != TimeVaryingMethod.NO) {
            if (
              indObj.permissionFlexSchedule_mode >= TimeVaryingMethod.YESINTIME &&
              indObj.permissionFlexSchedule_mode <= TimeVaryingMethod.NOAFTERTIME
            ) {
              return moment(Number(value), 'x').format('HH:mm');
            } else {
              return moment(Number(value), 'x').format("YYYY/MM/DD HH:mm");
            }
          }
        }
        else if (toFormat == "display" && lodash.isEmpty(indObj)) return value;
        break;

      case "accessparking_enddatetime":
      case "accessparking_startdatetime":
      case "accessparking_validafterdatetime":
      case "accessparking_invalidafterdatetime":
        if (toFormat == "dbValue") return value;
        else if (toFormat == "display" && !lodash.isEmpty(indObj) && value) {
          if (indObj.accessParking_mode != TimeVaryingMethod.YES && indObj.accessParking_mode != TimeVaryingMethod.NO) {
            if (
              indObj.accessParking_mode >= TimeVaryingMethod.YESINTIME &&
              indObj.accessParking_mode <= TimeVaryingMethod.NOAFTERTIME
            ) {
              return moment(Number(value), 'x').format('HH:mm');
            } else {
              return moment(Number(value), 'x').format("YYYY/MM/DD HH:mm");
            }
          }
        }
        else if (toFormat == "display" && lodash.isEmpty(indObj)) return value;
        break;

      case "accesssocial_enddatetime":
      case "accesssocial_startdatetime":
      case "accesssocial_validafterdatetime":
      case "accesssocial_invalidafterdatetime":
        if (toFormat == "dbValue") return value;
        else if (toFormat == "display" && !lodash.isEmpty(indObj) && value) {
          if (indObj.accessSocial_mode != TimeVaryingMethod.YES && indObj.accessSocial_mode != TimeVaryingMethod.NO) {
            if (
              indObj.accessSocial_mode >= TimeVaryingMethod.YESINTIME &&
              indObj.accessSocial_mode <= TimeVaryingMethod.NOAFTERTIME
            ) {
              return moment(Number(value), 'x').format('HH:mm');
            } else {
              return moment(Number(value), 'x').format("YYYY/MM/DD HH:mm");
            }
          }
        }
        else if (toFormat == "display" && lodash.isEmpty(indObj)) return value;
        break;

      case "accessathletics_enddatetime":
      case "accessathletics_startdatetime":
      case "accessathletics_validafterdatetime":
      case "accessathletics_invalidafterdatetime":
        if (toFormat == "dbValue") return value;
        else if (toFormat == "display" && !lodash.isEmpty(indObj) && value) {
          if (indObj.accessathletics_mode != TimeVaryingMethod.YES && indObj.accessathletics_mode != TimeVaryingMethod.NO) {
            if (
              indObj.accessathletics_mode >= TimeVaryingMethod.YESINTIME &&
              indObj.accessathletics_mode <= TimeVaryingMethod.NOAFTERTIME
            ) {
              return moment(Number(value), 'x').format('HH:mm');
            } else {
              return moment(Number(value), 'x').format("YYYY/MM/DD HH:mm");
            }
          }
        }
        else if (toFormat == "display" && lodash.isEmpty(indObj)) return value;
        break;

      case "additionalemail1":
      case "additionalemail2":
      case "additionalemail3":
      case "additionalemail4":
      case "additionalemail5":
        return lodash.chain(value).trim().toLower().value()

      default:
        if (value === "" || value === undefined || value === null) return null;
        return value;
    }
  }


  getDbValOfDynamicParameters(value: string) {
    switch (value) {
      case "true":
      case "yes":
      case "boolean yes":
      case "y":
      case "1":
      case "active":
        return TimeVaryingMethod.YES;

      case "false":
      case "no":
      case "boolean no":
      case "n":
      case "2":
      case "inactive":
        return TimeVaryingMethod.NO;

      case "time / yes in interval":
      case "time/yes in interval":
      case "time /yes in interval":
      case "time/ yes in interval":
      case "3":
        return TimeVaryingMethod.YESINTIME;

      case "time / no in interval":
      case "time/no in interval":
      case "time /no in interval":
      case "time/ no in interval":
      case "4":
        return TimeVaryingMethod.NOINTIME;

      case "time / yes after":
      case "time/yes after":
      case "time /yes after":
      case "time/ yes after":
      case "5":
        return TimeVaryingMethod.YESAFTERTIME;

      case "time / no after":
      case "time/no after":
      case "time /no after":
      case "time/ no after":
      case "6":
        return TimeVaryingMethod.NOAFTERTIME;

      case "date / yes in interval":
      case "date/yes in interval":
      case "date /yes in interval":
      case "date/ yes in interval":
      case "7":
        return TimeVaryingMethod.YESINDATETIME;

      case "date / no in interval":
      case "date/no in interval":
      case "date /no in interval":
      case "date/ no in interval":
      case "8":
        return TimeVaryingMethod.NOINDATETIME;

      case "date / yes after":
      case "date/yes after":
      case "date /yes after":
      case "date/ yes after":
      case "9":
        return TimeVaryingMethod.YESAFTERDATETIME;

      case "date / no after":
      case "date/no after":
      case "date /no after":
      case "date/ no after":
      case "10":
        return TimeVaryingMethod.NOAFTERDATETIME;

      default:
        return value
    }
  }

  getDisplayValOfDynamicParameters(val: number | boolean) {
    switch (val) {
      case true:
      case TimeVaryingMethod.YES:
        return "Yes";
      case false:
      case TimeVaryingMethod.NO:
        return "No";

      case TimeVaryingMethod.YESINTIME:
        return "Time / Yes In Interval";

      case TimeVaryingMethod.NOINTIME:
        return "Time / No In Interval";

      case TimeVaryingMethod.YESAFTERTIME:
        return "Time / Yes After";

      case TimeVaryingMethod.NOAFTERTIME:
        return "Time / No After";

      case TimeVaryingMethod.YESINDATETIME:
        return "Date / Yes In Interval";

      case TimeVaryingMethod.NOINDATETIME:
        return "Date / No In Interval";

      case TimeVaryingMethod.YESAFTERDATETIME:
        return "Date / Yes After";

      case TimeVaryingMethod.NOAFTERDATETIME:
        return "Date / No After";

      default:
        return val;
    }

  }
}
